<template>
  <div
    class="intel-brand-wrap"
    v-loading.fullscreen.lock="loading"
    element-loading-text="智能匹配中..."
  >
    <div class="nav-header">
      <div class="crumbs">
        <span @click="toBrandLib">品牌库 > </span> 品牌智能匹配
      </div>
      <el-tooltip
        class="item"
        popper-class="planTooltip"
        effect="dark"
        placement="bottom"
      >
        <div class="binding">您还可绑定 <span>{{projectMatchNum}}</span> 个项目</div>
        <div slot="content">如需绑定新项目请联系客服微信：win18578675071</div>
      </el-tooltip>
    </div>
    <div
      class="no-banding-project"
      v-if="hideHeader && projectList.length === 0"
    >
      <img
        class="intel-left"
        src="@/assets/images/intel-bg-left.png"
        alt=""
      >
      <img
        class="intel-right"
        src="@/assets/images/intel-bg-right.png"
        alt=""
      >
      <div class="banding-text">
        <i class="icon-intelligence"></i> 您可绑定 <span>{{projectMatchNum}}</span> 个智能匹配的项目
      </div>
      <div class="wechat-number">
        如需绑定新项目请联系客服微信：win18578675071
      </div>
    </div>
    <div v-if="projectList.length > 0">
      <div class="matching-wrap">
        <img
          class="intel-left"
          src="@/assets/images/intel-bg-left.png"
          alt=""
        >
        <img
          class="intel-right"
          src="@/assets/images/intel-bg-right.png"
          alt=""
        >
        <div class="project-name w-input-sty">
          <el-select
            v-model="projectId"
            @change="changeProject"
            popper-class="w-block-select-down"
            placeholder="请选择项目"
          >
            <el-option
              v-for="item in projectList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="select-input-list w-input-sty">
          <el-select
            v-model="floor"
            popper-class="w-block-select-down"
            @change="changeFloor"
            placeholder="匹配楼层"
          >
            <el-option
              v-for="item in floorList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="industryId"
            @change="changeIndustry"
            popper-class="w-block-select-down"
            placeholder="请选择业态"
          >
            <el-option
              v-for="item in typeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <div class="input-div input-number">
            <input
              type="number"
              v-model="useArea"
              placeholder="需匹配铺位的面积(输数字)"
            />
            <span>㎡</span>
          </div>
          <div class="input-div input-search">
            <input
              type="text"
              v-model="brandKeyWord"
              @input="searchInput"
              @focus="hideBusinessType"
              @blur="hideKeyworld"
              placeholder="需匹配的品牌(输入品牌名并选择)"
            />
            <div
              class="search-list"
              v-show="brandKeyWordListShow"
            >
              <ul>
                <li
                  v-show="brandKeyWordList.length > 0"
                  v-for="(item, index) in brandKeyWordList"
                  :key="index"
                  @click="selectBrandItem(item)"
                >{{item.brandName}}</li>
                <li v-show="brandKeyWordList.length === 0">无相关品牌数据！</li>
              </ul>
            </div>
          </div>
          <div
            class="intel-btn input-div"
            @click="toIntelMatch"
          >
            <i class="icon-intelligence"></i> 开始智能匹配
          </div>
        </div>
        <div class="tips">
          <i class="el-icon-warning"></i>
          智能匹配说明：基于赢商大数据4w+的品牌开店拓展需求及指数等数据，同时结合项目周边及铺位条件，智能匹配相似度比较高的品牌，以及品牌方入驻需求与本项目高契合度的品牌；由于数据采集时间周期性，品牌开店数据可能存在部分误差。
        </div>
      </div>
      <div class="intel-brand-list">
        <div class="brand-number">
          智能匹配 {{brandList.totalNum ? brandList.totalNum : 0}} 个品牌
        </div>
        <div class="order-wrap">
          <div class="exponent-order">
            <el-tooltip
              class="item"
              popper-class="planTooltip"
              effect="dark"
              placement="top"
            >
              <span
                @click="orderClick('defultScore')"
                :class="[sortBy === 'defultScore' ? 'active' : '']"
              >默认</span>
              <div slot="content">反映品牌综合实力，指数越高，代表品牌在同品类中行业优势地位越大。同等条件下能给场所端带来更高形象、更多流量、更稳定经营及更明显的羊群效应。</div>
            </el-tooltip>
            <el-tooltip
              class="item"
              popper-class="planTooltip"
              effect="dark"
              placement="top"
            >
              <span
                @click="orderClick('HotScore')"
                :class="[sortBy === 'HotScore' ? 'active' : '']"
              >
                人气指数 <i class="icon-order-down"></i>
              </span>
              <div slot="content">反映品牌受消费者关注的程度。指数越高，代表品牌在同品类中能吸引更多的受众关注。</div>
            </el-tooltip>
            <el-tooltip
              class="item"
              popper-class="planTooltip"
              effect="dark"
              placement="top"
            >
              <span
                @click="orderClick('PotentialScore')"
                :class="[sortBy === 'PotentialScore' ? 'active' : '']"
              >
                潜力指数 <i class="icon-order-down"></i>
              </span>
              <div slot="content">反映近5年创立的新品牌发展潜力程度。指数越高，代表品牌在同品类的新品牌中，发展势头更高。</div>
            </el-tooltip>
            <el-tooltip
              class="item"
              popper-class="planTooltip"
              effect="dark"
              placement="top"
            >
              <span
                @click="orderClick('OperatorScore')"
                :class="[sortBy === 'OperatorScore' ? 'active' : '']"
              >
                租金贡献指数 <i class="icon-order-down"></i>
              </span>
              <div slot="content">反映品牌的租金贡献能力，指数越高，代表相同条件下（同品类，同面积），该品牌整体上能给商铺贡献更高租金或租金溢价。</div>
            </el-tooltip>
          </div>
          <div class="select-type w-input-sty">
            <el-select
              v-if="nextShow"
              v-model="industryId2"
              @change="changeIndustryId2"
              popper-class="w-block-select-down"
              size=""
              placeholder="请选择"
            >
              <el-option
                v-for="item in industryIdList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="check-wrap">
            <el-checkbox-group
              v-model="matchList"
              @change="matchChange"
            >
              <el-tooltip
                class="item"
                popper-class="planTooltip"
                effect="dark"
                placement="top"
              >
                <el-checkbox label="5">楼层偏好匹配</el-checkbox>
                <div slot="content">偏好本楼层大于30%的品牌</div>
              </el-tooltip>
              <el-tooltip
                class="item"
                popper-class="planTooltip"
                effect="dark"
                placement="top"
              >
                <el-checkbox label="1">铺位面积匹配</el-checkbox>
                <div slot="content">开店面积与铺位面积相匹配的品牌</div>
              </el-tooltip>
              <el-tooltip
                class="item"
                popper-class="planTooltip"
                effect="dark"
                placement="top"
              >
                <el-checkbox label="2">品牌档次匹配</el-checkbox>
                <div slot="content">品牌档次与首选落位品牌一致的品牌</div>
              </el-tooltip>
              <el-tooltip
                class="item"
                popper-class="planTooltip"
                effect="dark"
                placement="top"
              >
                <el-checkbox label="3">业态标签匹配</el-checkbox>
                <div slot="content">业态标签与首选落位品牌有重合的品牌</div>
              </el-tooltip>
              <el-tooltip
                class="item"
                popper-class="planTooltip"
                effect="dark"
                placement="top"
              >
                <el-checkbox label="4">品牌拓展意向</el-checkbox>
                <div slot="content">品牌在当前项目拓展开店的意向</div>
              </el-tooltip>
            </el-checkbox-group>
            <div class="percentage">
              <span>{{rankText}}</span>
              <div class="ranks">
                <el-tooltip
                  class="item"
                  popper-class="planTooltip"
                  effect="dark"
                  placement="top"
                >
                  <a
                    class="first"
                    @click="ranksClick(1)"
                    :class="[ranksIdx === 1 ? 'active' : '']"
                    href="javascript:;"
                  >
                    <span class="bg"></span>
                    <span
                      class="bar"
                      style="height: 15px;"
                    ></span>
                  </a>
                  <div slot="content">进驻概率大于60%的品牌</div>
                </el-tooltip>
                <el-tooltip
                  class="item"
                  popper-class="planTooltip"
                  effect="dark"
                  placement="top"
                >
                  <a
                    @click="ranksClick(2)"
                    :class="[ranksIdx === 2 ? 'active' : '']"
                    href="javascript:;"
                  >
                    <span class="bg"></span>
                    <span
                      class="bar"
                      style="height: 11px;"
                    ></span>
                  </a>
                  <div slot="content">进驻概率介于30%到60%的品牌</div>
                </el-tooltip>
                <el-tooltip
                  class="item"
                  popper-class="planTooltip"
                  effect="dark"
                  placement="top"
                >
                  <a
                    @click="ranksClick(3)"
                    :class="[ranksIdx === 3 ? 'active' : '']"
                    href="javascript:;"
                  >
                    <span class="bg"></span>
                    <span
                      class="bar"
                      style="height: 6px;"
                    ></span>
                  </a>
                  <div slot="content">进驻概率小于30%的品牌</div>
                </el-tooltip>
              </div>
            </div>
          </div>
        </div>
        <div class="result-list">
          <div
            class="list-item"
            v-for="item in intelBrandList"
            :key="item.id"
            @click="toDetailsClick(item)"
          >
            <div class="brand-item-wrap">
              <div class="item-logo">
                <img
                  :src="item.brandLogo"
                  alt=""
                >
                <span>{{item.brandName}}</span>
              </div>
              <p v-if="item.areaMin">
                <span>{{item.industry}}</span>{{item.areaMin?'/'+item.areaMin+'-':''}}{{item.areaMax?item.areaMax+'平米':''}}
              </p>
              <p v-if="!item.areaMin">{{item.industry}}</p>
              <p
                class="ellipsis"
                v-if="item.shopNum"
              >{{'已进驻' + item.shopNum + '家购物中心'}}{{item.shopNum && item.chainMarketNum ? '+': ''}}{{item.chainMarketNum>0?item.chainMarketNum + '家百货':''}}</p>
              <p
                class="ellipsis"
                v-else
              >{{'已进驻' + item.shopNum + '家购物中心'}}{{item.shopNum && item.chainMarketNum ? '+': ''}}{{item.chainMarketNum>0?'已进驻' + item.chainMarketNum + '家百货':''}}</p>
              <span
                v-if="item.tagName"
                class="brand-label-list"
              >{{item.tagName}}</span>
            </div>
          </div>
        </div>
        <div class="clearfix"></div>
        <div
          class="details-no-data"
          v-if="intelBrandList && intelBrandList.length === 0"
        >
          <img
            src="@/assets/images/public/not-plandata.png"
            alt=""
          >
          <p>抱歉，暂无推荐品牌</p>
        </div>
        <div class="w-page-sty">
          <el-pagination
            v-if="brandList.totalNum > 20"
            background
            layout="prev, pager, next"
            @current-change="pageChange"
            :page-size="pageSize"
            :current-page="pageNum"
            :total="brandList.totalNum"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
export default {
  data () {
    return {
      hideHeader: false,
      projectId: '',
      floor: '',
      industryId: '零售',
      typeList: [],
      projectList: [],
      floorList: '',
      sortBy: 'defultScore',
      matchList: [],
      brandList: {},
      intelBrandList: [],
      useArea: '',
      brandKeyWord: '',
      ranksIdx: null,
      rankText: '',
      brandKeyWordList: [],
      brandKeyWordListShow: false,
      currentKeywordBrand: {},
      selectBrandItemData: {},
      projectMatchNum: 0,
      pageNum: 1,
      industryId2: '不限',
      industryIdList: [],
      nextShow: true,
      loading: false,
      pageSize: 0
    }
  },
  mounted () {
    this.getBerthProjectFunc()
    this.getListIndustry()
    const clientWidth = `${document.documentElement.clientWidth}`
    if (clientWidth > 1500) {
      this.pageSize = 40
    } else {
      this.pageSize = 36
    }
  },
  methods: {
    getBerthProjectFunc () {
      this.axios.post(api.getIntelProjectList)
        .then((res) => {
          this.projectMatchNum = res.data.data.projectMatchNum
          if (res.data.data.projectList && res.data.data.projectList.length === 0) {
            this.hideHeader = true
          } else {
            this.projectList = res.data.data.projectList.map((item) => {
              let obj = {}
              obj = item
              obj.label = item.projectName
              obj.value = item.projectId
              return obj
            })
            this.projectId = this.projectList[0].value
            this.getFloorList()
            this.hideHeader = false
          }
        })
    },
    changeProject (val) {
      // console.log(val)
      this.floor = ''
      this.getFloorList()
    },
    getFloorList () {
      this.axios.post(api.getFloorListSelect, { projectId: this.projectId })
        .then((res) => {
          this.floorList = res.data.data
        })
    },
    getListIndustry () {
      this.axios.post(api.getListIndustry1).then((res) => {
        this.typeList = res.data.data.map(item => {
          return {
            value: item.id,
            label: item.name
          }
        })
        this.toIntelMatch()
      })
    },
    searchInput () {
      this.fetchBrandByKeyworld()
    },
    fetchBrandByKeyworld () {
      let brandKeyWord = ''
      this.brandLoad = true
      if (this.brandKeyWord !== '' && this.brandKeyWord !== '请输入你想添加的品牌名称') {
        brandKeyWord = this.brandKeyWord
      }
      if (brandKeyWord) {
        this.brandKeyWordList = []
        this.brandKeyWordListShow = true
        this.axios.post(api.searchBrandListMatching, { brandName: this.brandKeyWord })
          .then((res) => {
            this.brandLoad = false
            if (res.data.code === 0) {
              this.currentKeywordBrand = ''
              this.brandKeyWordList = res.data.data.map((item) => {
                if (item.brandName === this.brandKeyWord) {
                  this.currentKeywordBrand = item
                }
                // 处理默认选中状态为true
                item.active = true
                return item
              })
            }
          })
      } else {
        this.brandKeyWordListShow = false
        this.currentKeywordBrand = {}
        this.selectBrandItemData = {}
      }
    },
    hideKeyworld () {
      if (this.currentKeywordBrand.gbid === '') {
        this.brandKeyWordListShow = false
      }
    },
    hideBusinessType () {
      if (this.brandKeyWord === '') {
        this.brandKeyWord = ''
        this.currentKeywordBrand = {}
        this.selectBrandItemData = {}
      }
      // console.log(this.selectBrandItemData)
    },
    selectBrandItem (item) {
      this.currentKeywordBrand = item
      this.brandKeyWord = item.brandName
      this.brandKeyWordListShow = false
    },
    changeFloor (val) {
      // console.log(val)
    },
    orderClick (index) {
      this.loading = true
      this.sortBy = index
      this.pageNum = 1
      this.fetchIntelBrandList()
    },
    matchChange () {
      if (this.matchList.indexOf('4') > -1) {
        this.loading = true
        this.rankText = '较强'
        this.ranksIdx = 1
        this.fetchIntelBrandList()
      } else {
        if (this.rankText) {
          this.loading = true
          this.rankText = ''
          this.ranksIdx = null
          this.fetchIntelBrandList()
        }
      }
      if (this.matchList.indexOf('5') > -1) {
        if (!this.floor) {
          this.$message({
            type: 'warning',
            message: '请先选择需匹配的楼层！'
          })
          for (let i = 0; i < this.matchList.length; i++) {
            if (this.matchList[i] === '5') {
              this.matchList.splice(i, 1)
            }
          }
        }
      }
      if (this.matchList.indexOf('1') > -1) {
        if (!this.useArea) {
          this.$message({
            type: 'warning',
            message: '请先输入需匹配的铺位面积！'
          })
          for (let i = 0; i < this.matchList.length; i++) {
            if (this.matchList[i] === '1') {
              this.matchList.splice(i, 1)
            }
          }
        }
      }
      if (this.matchList.indexOf('2') > -1 || this.matchList.indexOf('3') > -1) {
        if (!this.selectBrandItemData.brandName) {
          this.$message({
            type: 'warning',
            message: '请先选择需匹配的品牌！'
          })
          for (let i = 0; i < this.matchList.length; i++) {
            if (this.matchList[i] === '2' || this.matchList[i] === '3') {
              this.matchList.splice(i, 1)
            }
          }
        }
      }
    },
    ranksClick (idx) {
      this.loading = true
      if (this.matchList.indexOf('4') === -1) {
        this.matchList.push('4')
      }
      this.ranksIdx = idx
      this.rankText = idx === 1 ? '较强' : (idx === 2 ? '一般' : '较弱')
      this.pageNum = 1
      this.fetchIntelBrandList()
    },
    toIntelMatch () {
      this.loading = true
      this.nextShow = false
      if (this.floor) {
        this.matchList.push('5')
      }
      if (this.useArea) {
        this.matchList.push('1')
      }
      this.industryId2 = '不限'
      this.industryIdList = []
      this.pageNum = 1
      // console.log(this.currentKeywordBrand)
      this.fetchIntelBrandList()
    },
    changeIndustry () {
      // this.loading = true
    },
    changeIndustryId2 (val) {
      this.loading = true
      this.pageNum = 1
      this.fetchIntelBrandList()
    },
    fetchIntelBrandList () {
      let industryId = this.industryId
      if (this.currentKeywordBrand.brandName) {
        this.selectBrandItemData = this.currentKeywordBrand
        industryId = this.currentKeywordBrand.typeName
      }
      const data = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        projectId: this.projectId,
        sortBy: this.sortBy,
        industryId: industryId,
        areaMatch: this.matchList.indexOf('1') === -1 ? 0 : 1,
        brandGradeMatch: this.matchList.indexOf('2') === -1 ? 0 : 1,
        duelMatch: this.matchList.indexOf('4') === -1 ? 0 : 1,
        duelScreen: this.ranksIdx > 0 ? this.ranksIdx : 0,
        tagnameMatch: this.matchList.indexOf('3') === -1 ? 0 : 1,
        floorMatch: this.matchList.indexOf('5') === -1 ? 0 : 1,
        berthFloor: this.floor ? this.floor : 0,
        planId: 0,
        useArea: this.useArea ? this.useArea : 0,
        industryId2: (this.industryId2 !== '不限' && this.industryId2 !== 0) ? this.industryId2 : 0,
        brandId: this.selectBrandItemData.brandId ? this.selectBrandItemData.brandId : 0,
        gbid: this.selectBrandItemData.gbid ? this.selectBrandItemData.gbid : 0
      }
      this.axios.post(api.getIntelBrandList, data)
        .then((res) => {
          this.nextShow = true
          this.industryIdList = res.data.data.data.map((item) => {
            return {
              value: item.id,
              label: item.item
            }
          })
          this.brandList = res.data.data
          this.intelBrandList = res.data.data.pagedResult
          if (res.data.data.searchIndustry1) {
            this.industryId = res.data.data.searchIndustry1
          }
          if (res.data.data.searchIndustry2) {
            this.industryId2 = res.data.data.searchIndustry2
          }
          this.loading = false
        })
    },
    pageChange (val) {
      this.loading = true
      this.pageNum = val
      this.fetchIntelBrandList()
    },
    toBrandLib () {
      this.$router.push({
        path: '/selfBrandList'
      })
    },
    toDetailsClick (item) {
      const brandDetailsHref = this.$router.resolve({
        path: '/brandDetail',
        query: {
          id: item.brandId,
          source: 1,
          gbid: item.gbid,
          questionState: 0
        }
      })
      window.open('/' + brandDetailsHref.href, '_blank')
    }
  }
}
</script>

<style lang="stylus" scoped>
.intel-brand-wrap
  padding 0 24px
  padding-bottom 50px
.nav-header
  display flex
  justify-content space-between
  margin 20px 0
  .crumbs
    color rgba(255, 255, 255, 0.5)
    font-size 14px
    font-weight 500
    cursor pointer
    span
      color rgba(255, 255, 255, 1)
  .binding
    color #ffffff
    font-size 14px
    span
      color #FFA234
.matching-wrap
  position relative
  width 100%
  height 242px
  border-top 2px solid #56606E
  border-bottom 2px solid #56606E
  background-color #282A30
  background-color, .intel-left
    position absolute
    left 0
    top -2px
  .intel-right
    position absolute
    right 0
    top -2px
  .project-name
    padding-top 40px
    margin-left 40px
  .select-input-list
    margin-left 40px
    margin-top 40px
    input
      width 298px
      height 38px
      background rgba(35, 37, 44, 1)
      box-shadow 0px 7px 35px 0px rgba(0, 0, 0, 0.43)
      border-radius 3px
      border 1px solid rgba(102, 102, 103, 1)
      border-radius 3px
      color #fff
      font-size 14px
      padding-left 12px
      margin-right 32px
    .intel-btn
      width 152px
      background #FFA234
      border 3px solid #FFB55E
      box-shadow 1px 4px 0px 0px rgba(92, 53, 7, 0.11)
      font-size 16px
      font-weight 500
      color #ffffff
      vertical-align middle
      line-height 34px
      text-align center
      cursor pointer
      .icon-intelligence
        vertical-align middle
        color #ffffff
        font-size 16px
    .input-div
      display inline-block
  .tips
    color rgba(255, 255, 255, 0.5)
    font-size 14px
    font-weight 400
    line-height 22px
    margin-left 40px
    margin-top 24px
    padding-right 20px
.intel-brand-list
  margin-top 16px
  background-color #282A30
  padding 30px 22px 50px 22px
  border-radius 3px
  padding-bottom 50px
  .brand-number
    font-size 16px
    font-weight 500
    color #ffffff
    margin-bottom 30px
  .order-wrap
    display flex
    .exponent-order
      span
        display inline-block
        // width 91px
        height 38px
        padding 0 12px
        border 1px solid rgba(255, 255, 255, 0.5)
        vertical-align middle
        margin-right -1px
        text-align center
        color rgba(255, 255, 255, 0.5)
        font-size 14px
        font-weight 500
        line-height 38px
        cursor pointer
        position relative
        &:first-child
          border-radius 3px 0 0 3px
          line-height 38px
        &:last-child
          border-radius 0px 3px 3px 0px
        &.active
          border-color #FFA234
          color #FFA234
          z-index 111
          i:after
            color #FFA234
  .check-wrap
    position relative
    line-height 36px
    .percentage
      position absolute
      right -110px
      top 8px
      &>span
        float left
        width 38px
        height 20px
        font-size 12px
        line-height 20px
        text-align center
        border 1px solid rgba(149, 150, 151, 0.5)
        border-radius 2px
        color #fff
      .ranks
        float left
        margin-left 10px
        .first
          width 16px
          .bg
            border-left 1px solid rgba(149, 150, 151, 1)
          .bar
            left 0
        .active
          .bar
            background #ffa134
        a
          position relative
          float left
          height 20px
          width 15px
          &:hover
            .bar
              background #ffa134
          .bg
            position absolute
            top 0
            left 0
            height 20px
            width 14px
            border 1px solid rgba(149, 150, 151, 1)
            border-left none
            background #272930
          .bar
            position absolute
            left -1px
            bottom -2px
            width 15px
            border-right 1px solid rgba(149, 150, 151, 1)
            background rgba(255, 255, 255, 0.2)
  .result-list
    margin-top 24px
    padding 24px 40px 24px 40px
    border-top 1px solid #36363A
    .list-item
      position relative
      float left
      width 200px
      height 172px
      background #fff
      padding 10px
      box-shadow 0px 9px 32px 0px rgba(50, 29, 6, 0.06)
      box-sizing border-box
      margin-bottom 16px
      // margin-right 10px
      margin-left 8px
      margin-right 8px
      .brand-item-wrap
        cursor pointer
      // &:nth-child(8n)
      // margin-right 0
      .item-logo
        display flex
        align-items center
        margin-bottom 15px
        img
          width 77px
          height 57px
          object-fit cover
        span
          font-size 14px
          font-weight 500
          margin-left 10px
          text-overflow ellipsis
          display -webkit-box
          -webkit-line-clamp 2
          -webkit-box-orient vertical
          overflow hidden
          text-decoration underline
          color #333
      p
        font-size 12px
        line-height 18px
        font-weight 500
        color #666
.details-no-data
  margin-top 100px
  text-align center
  p
    color #fff
    font-size 12px
    margin-top 10px
.no-banding-project
  position relative
  width 903px
  height 242px
  margin auto
  margin-top 180px
  margin-bottom 10px
  border-top 2px solid #56606e
  border-bottom 2px solid #56606e
  background-color #282a30
  .intel-left
    position absolute
    left 0
    top -2px
  .intel-right
    position absolute
    right 0
    top -2px
  .banding-text
    font-size 16px
    text-align center
    color #fff
    margin-top 96px
    .icon-intelligence
      vertical-align baseline
      color #ffffff
      font-size 16px
    span
      color #ffa134
  .wechat-number
    font-size 12px
    font-weight 500
    color #fff
    margin-top 22px
    text-align center
.input-number
  position relative
  span
    position absolute
    right 40px
    top 12px
    color #ffffff
    font-size 14px
    font-weight 500
.input-search
  position relative
.search-list
  position absolute
  top 40px
  left 0px
  width 230px
  max-height 320px
  padding-top 1px
  z-index 11111
  background-color #272930
  overflow-y scroll
  box-shadow 0px 7px 35px 0px rgba(0, 0, 0, 0.43)
  border-radius 5px
  // border-top 1px solid #ccc
  li
    height 36px
    line-height 36px
    text-align left
    text-indent 24px
    // border-bottom 1px solid #cccccc
    font-size 14px
    cursor pointer
    color #fff
    &:hover
      background-color rgba(246, 247, 250, 0.1)
      color #fff
.brand-label-list
  display inline-block
  font-size 12px
  color #b8a794
  padding 4px 6px
  border-radius 6px
  background-color #fff8f0
  margin-top 18px
.select-type
  margin-left 12px
.search-list
  &::-webkit-scrollbar
    width 6px
  &::-webkit-scrollbar-track
    // background-color: #f2f2f2;
    border-radius 10px
  /* 滚动条的滑轨背景颜色 */
  &::-webkit-scrollbar-thumb
    background-color rgba(151, 168, 190, 0.3)
    border-radius 10px
  /* 滑块颜色 */
  &::-webkit-scrollbar-button
    // background-color: #000;
  /* 滑轨两头的监听按钮颜色 */
  &::-webkit-scrollbar-corner
    // background-color: black;
  /* 横向滚动条和纵向滚动条相交处尖角的颜色 */
</style>
